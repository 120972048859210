$icon-size: 45px;

@import '~react-pro-sidebar/dist/scss/styles.scss';
@import '~semantic-ui-css/semantic.css';
@import '~react-phone-number-input/style.css';

body {
  margin: 0;
}

* {
  font-family: Nunito;
  font-weight: 400;
}

@font-face {
  font-family: PlayfairDisplay;
  src: url('../assets/font/Playfair_Display/PlayfairDisplay-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Nunito;
  src: url('../assets/font/Nunito_Sans/NunitoSans-Regular.ttf');
  font-weight: 400;
}


@font-face {
  font-family: Nunito;
  src: url('../assets/font/Nunito_Sans/NunitoSans-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: Nunito;
  src: url('../assets/font/Nunito_Sans/NunitoSans-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: Nunito;
  src: url('../assets/font/Nunito_Sans/NunitoSans-Bold.ttf');
  font-weight: bold;
}

.ui .page .modals {
  z-index: 1010;
}
